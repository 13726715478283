import Accordion from "../Accordion/Accordion";
import ExternalSvgList from "../SVG/ExternalSvg";
import { Tab } from "@headlessui/react";
import { ITabs } from "./interfaces";
import StaticCarousel from "../Carousel/StaticCarousel";

const TabsContent = (props: ITabs) => {
  switch (props.contentType) {
    case "svg":
      return <ExternalSvgList items={props.items} />
    case "video":
      return <StaticCarousel items={props.items} />
    default:
      return <Accordion parentClassName="accordion" items={props.items} />
  }
};

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
}

const Tabs = (props: {
  className?: string;
  data: ITabs;
}) => {
  const {
    contentType,
    ...categories
  } = props.data;
  
  return (
    <div className={`w-full ${props.className ?? ""}`}>
      <Tab.Group>
        <Tab.List className="max-w-max overflow-x-scroll scroll flex gap-2 sm:bg-secondary  pb-0 sm:p-1 sm:px-4 sm:rounded-full border-b-2 border-gray-300 sm:border-none">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "shrink-0  px-4 p-2.5 text-sm font-medium leading-5",
                  "focus-visible:outline-none",
                  selected
                    ? "text-fill-inverted sm:text-text-inverted border-b-2 border-fill-inverted sm:rounded-full sm:bg-fill-inverted"
                    : ""
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="py-4 self-stretch">
          {Object.values(categories).map((items, idx) => (
            <Tab.Panel
              key={idx}
              className={({ selected }) =>
                classNames(
                  "rounded-xl",
                  selected
                    ? "visible  opacity-100"
                    : "invisible h-0 opacity-0 pointer-events-none"
                )
              }
              static
            >
              <TabsContent contentType={contentType} items={items} />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default Tabs;