import Shimmer from "@/utils/shimmer";
import Image from "next/image";
import LinkWrapper from "../LinkWrapper";

interface IExternalSvgListItem {
  href: string;
  imgUrl?: string;
  alt?: string;
  svg?: string;
}
const ExternalSvgListItem = (props: {item: IExternalSvgListItem}) => {
  const item = props.item;
  return <>
    {item.imgUrl && <LinkWrapper className="hover-pop" href={item.href} >
      <Image className="my-0 w-full h-full" src={item.imgUrl} alt={item.alt as string} width={150} height={150} placeholder="blur" blurDataURL={Shimmer(150, 150)} />
    </LinkWrapper>}
    
    {(!item.imgUrl && item.svg) && <LinkWrapper className="hover-pop" href={item.href} dangerouslySetInnerHTML={{__html: item.svg}}></LinkWrapper>}
  </>
}

const ExternalSvgList = (props: {className?:string; items:IExternalSvgListItem[]}) => {
  return <div className={`grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 lg:gap-8 ${props.className}`}>
    {props.items?.map((item, index) => (
      <ExternalSvgListItem key={index.toString()} item={item} />
    ))}
  </div>
}

export default ExternalSvgList;